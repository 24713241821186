import * as React from "react";
import { graphql } from "gatsby";
import { Box, Container, Heading } from "@chakra-ui/react";
import { getImage } from "gatsby-plugin-image"
import Seo from "../components/seo"
import PageIceMask from "../components/IceMasks/PageIceMask";
import SecondaryHero from "../components/SecondaryHero/SecondaryHero";
import HomeLowerCurve from "../components/HomePage/HomeLowerCurve";
import ProgramsCardsIsolated from "../components/ProgramsCards/ProgramsCardsIsolated";

import StripeDivder from "../components/IceMasks/StripeDivider";
import BackgroundImage from '../components/Backgrounds/BackgroundImage'
import DonateNewsletterBlock from "../components/DonateNewsletterBlock/DonateNewsletterBlock";
import DonationForm from "../components/Donate/DonationForm"
import Footer from "../components/Footer/Footer"
import StripeWrapper from "../components/Donate/StripeWrapper"
import SliceZone from "../components/SliceZone/SliceZone";

const ProgramsPage = ({ data }) => {
  if (!data) return null;
  const document = data.allPrismicPage.edges[0].node.data;
  const secondaryImage = document.secondary_hero_image.localFile;
  const backgroundImage = data.allFile.edges[0].node.childImageSharp;

  return (
    <>
    <Seo title={document.page_title.text} />
      <PageIceMask 
        title={document.info_oval_title}
        description={document.info_oval_content.richText}
        buttonOneLinkTo={document.info_oval_button_1_link.url}
        buttonOneText={document.info_oval_button_1_label}
        buttonTwoLinkTo={document.info_oval_button_2_link.url}
        buttonTwoText={document.info_oval_button_2_label}
        mr="10%"
        mt="12%"
      />
      <SecondaryHero
        secondaryImg={secondaryImage}
        alt={document.secondary_hero_image.alt}
      />
      <Box
        style={{
          width: "100%",
          marginTop: "15%",
          marginBottom: '-1px'
        }}
        display={{base: 'none', lg: 'block'}}
      >
        <HomeLowerCurve
          pageTitle={document.page_title.text}
          subTitle={document.page_subtitle.text}
        />
      </Box>
      <Container>
        <Box pt={{base: 32, lg: 0 }}  px="2" textAlign="center" display={{base: 'block', lg: 'none'}} >
              <Heading as="h1" variant="page-title" lineHeight="1" fontSize="2.5rem">
              {document.page_title.text}
                </Heading>
                <Heading as="h3" color="brand.600" fontSize="1.5rem">
                  {document.page_subtitle.text}
                </Heading>
                
            </Box>
      </Container>

      <Box backgroundColor="white">
        <Container maxW="container.xl">
          <SliceZone sliceZone={document.body} />
        <Box mb="20">          
          <StripeWrapper />
        </Box>
        </Container>
        
        <BackgroundImage
          backgroundImage={getImage(backgroundImage)}
          >
        <StripeDivder />
        
        
        <DonateNewsletterBlock
          title="Your Support Makes All The Difference"
          content="Now, of course not, Biff, now, I wouldn't want that to happen. Well, now we gotta sneak this back into my laboratory, we've gotta get you home. Yeah. I can't play. Just say anything, George, say what ever's natural, the first thing that comes to your mind."
          btnText="Donate Now"
          btnLink="/donate"
        />
        <Footer />
      </BackgroundImage>
      </Box>
    </>
  );
};

export const query = graphql`
  {
    allFile(filter: {relativePath: {eq: "Ice.jpg"}}) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, quality: 80)
          }
        }
      }
    }
    allPrismicPage(filter: { uid: { eq: "donate" } }) {
      edges {
        node {
          uid
          data {
            hero_image {
              localFile {
                absolutePath
                publicURL
              }
              alt
            }
            secondary_hero_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                }
              }
            }
            page_title {
              text
            }
            page_subtitle {
              text
            }
            info_oval_button_1_label
            info_oval_button_1_link {
              url
              target
            }
            info_oval_button_2_label
            info_oval_button_2_link {
              url
              target
            }
            info_oval_title
            info_oval_content {
              richText
            }
            body {
              ... on PrismicPageDataBodyPageContent {
                id
                slice_type
                primary {
                  page_content {
                    richText
                  }
                }
              }
              ... on PrismicPageDataBodyBannerAdsCarousel {
                id
                slice_type
                primary {
                  banner_ads {
                    document {
                      ... on PrismicBannerAds {
                        id
                        data {
                          banner_ad {
                            mobile_image {
                              alt
                              localFile {
                                childImageSharp {
                                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                                }
                              }
                            }
                            ad_link {
                              url
                            }
                            desktop_image {
                              alt
                              localFile {
                                childImageSharp {
                                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default ProgramsPage;
